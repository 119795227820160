import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#E0E0E0',
    [Prop.HEADER]: '#3a3a3a',
    [Prop.CONTENT]: '#696969',
    [Prop.LINK_TXT]: '#C7DA2C',
    [Prop.LINK_TXT_HOVER]: '#3a3a3a',
    [Prop.BTN_BG]: '#FFFFFF',
    [Prop.BTN_TXT]: '#C7DA2C',
    [Prop.BTN_BG_HOVER]: '#3a3a3a',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.ELEMENT]: '#696969',
    [Prop.ELEMENT_TXT]: '#696969',
    [Prop.BORDER]: '#FFFFFF',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#333333',
    [Prop.HEADER]: '#E0E0E0',
    [Prop.CONTENT]: '#E0E0E0',
    [Prop.LINK_TXT]: '#C7DA2C',
    [Prop.LINK_TXT_HOVER]: '#3a3a3a',
    [Prop.BTN_BG]: '#696969',
    [Prop.BTN_TXT]: '#C7DA2C',
    [Prop.BTN_BG_HOVER]: '#3a3a3a',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#696969',
    [Prop.ELEMENT_TXT]: '#696969',
    [Prop.BORDER]: '#C7DA2C',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#E0E0E0',
    [Prop.LINK_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG]: '#E0E0E0',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#3a3a3a',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#696969',
    [Prop.ELEMENT_TXT]: '#696969',
    [Prop.BORDER]: '#C7DA2C',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#3a3a3a',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#E0E0E0',
    [Prop.LINK_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#C7DA2C',
    [Prop.BTN_BG_HOVER]: '#333333',
    [Prop.BTN_TXT_HOVER]: '#C7DA2C',
    [Prop.BTN_BG_ACTIVE]: '#333333',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#696969',
    [Prop.ELEMENT_TXT]: '#696969',
    [Prop.BORDER]: '#C7DA2C',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#C7DA2C',
    [Prop.HEADER]: '#E0E0E0',
    [Prop.CONTENT]: '#E0E0E0',
    [Prop.LINK_TXT]: '#696969',
    [Prop.LINK_TXT_HOVER]: '#E0E0E0',
    [Prop.BTN_BG]: '#696969',
    [Prop.BTN_TXT]: '#E0E0E0',
    [Prop.BTN_BG_HOVER]: '#E0E0E0',
    [Prop.BTN_TXT_HOVER]: '#696969',
    [Prop.BTN_BG_ACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_ACTIVE]: '#FFFFFF',
    [Prop.BTN_BG_INACTIVE]: '#3a3a3a',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.ELEMENT]: '#696969',
    [Prop.ELEMENT_TXT]: '#696969',
    [Prop.BORDER]: '#C7DA2C',
    [Prop.ICON]: '#FFFFFF',
    [Prop.ICON_HOVER]: '#3a3a3a',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#FFFFFF',
    [Prop.NAV_BG]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#3a3a3a',
    [Prop.NAV_ITEM_TXT_SOLID]: '#3a3a3a',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#3a3a3a',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#FFFFFF',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
};
