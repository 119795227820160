module.exports = {
  // TODO: Figure out which fields are actually needed here then remove the rest from all properties
  gatsbyConfig: {
    property: 'gravicross',
    siteName: 'GraviCross',
    siteUrl: 'https://www.gravicross.com/',
    reCaptchaDBPropertyName: 'Gravicross',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    dynamicTypes: [],
  },
};
